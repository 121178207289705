var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"detailsLessonForm",attrs:{"tag":"form"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"d-flex align-items-center justify-content-between flex-wrap pb-0"},[_c('strong',{staticClass:"pb-2 m-auto mx-lg-0"},[_vm._v("تفاصيل الدرس")]),_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"d-flex align-items-center justify-content-between",attrs:{"label":"اسم الدرس","label-for":"lessonName"}},[_c('validation-provider',{attrs:{"name":"اسم الدرس","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"max-width":"200px"},attrs:{"type":"text","id":"lessonName","state":errors.length > 0
                                                    ? false
                                                    : null,"placeholder":"اسم الدرس"},model:{value:(_vm.lessonDto.name),callback:function ($$v) {_vm.$set(_vm.lessonDto, "name", $$v)},expression:"lessonDto.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"الوحدة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('label',{staticClass:"mr-1",attrs:{"for":"followUnit"}},[_vm._v("تابع للوحدة")]),_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"followUnit","dir":_vm.$store.state.appConfig
                                                        .isRTL
                                                        ? 'ltr'
                                                        : 'rtl',"label":"name","reduce":function (option) { return option.id; },"options":_vm.unitsList},model:{value:(_vm.lessonDto.unitId),callback:function ($$v) {_vm.$set(_vm.lessonDto, "unitId", $$v)},expression:"lessonDto.unitId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{staticClass:"d-flex align-items-center justify-content-between",attrs:{"label":"الترتيب","label-for":"lessonOrder"}},[_c('validation-provider',{attrs:{"name":"الترتيب","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                        var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"min-width":"40px","max-width":"200px"},attrs:{"type":"number","id":"lessonOrder","state":errors.length > 0
                                                    ? false
                                                    : null},model:{value:(_vm.lessonDto.lessonOrder),callback:function ($$v) {_vm.$set(_vm.lessonDto, "lessonOrder", $$v)},expression:"lessonDto.lessonOrder"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"mb-1 mx-lg-0 ml-1 ml-auto"},[_c('b-button',{attrs:{"variant":"primary","size":"md","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.updateLessonValidationForm($event)}}},[_vm._v(" تعديل ")]),_c('b-button',{staticClass:"ml-1",attrs:{"size":"md","variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.SubmitRemoveLesson()}}},[_vm._v(" حذف ")])],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"3","md":"6","order":"1"}},[_c('b-card',{staticClass:"mx-auto",staticStyle:{"max-width":"420px"},attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_vm._v("اختر نوع الملفات")]),_c('b-form-group',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-50"},[_c('label',[_vm._v("ملف فيديو")]),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.fileType),callback:function ($$v) {_vm.fileType=$$v},expression:"fileType"}}),_c('label',[_vm._v("ملف pdf")])],1)]),_c('b-card-header',{staticClass:"pb-50"},[_vm._v("اختر الملف")]),_vm._l((_vm.lessonDto.lessonVideos),function(video,index){return _c('b-card-body',{key:index,staticClass:"py-0"},[(video.fileType == _vm.fileType)?_c('router-link',{attrs:{"to":("/lessons/" + _vm.id + "/" + (video.id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var href = ref.href;
                                                    var route = ref.route;
                                                    var navigate = ref.navigate;
                                                    var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"rounded overflow-hidden mb-1",class:{ 'box-shadow': isActive }},[_c('img',{staticClass:"w-100",staticStyle:{"object-fit":"contain"},attrs:{"src":!!video.thumbPath
                                                ? video.thumbPath
                                                : video.fileType 
                                                        ? _vm.$store.getters[
                                                            'app/placeHolderpdf'
                                                        ]
                                                        :  _vm.$store.getters[
                                                            'app/placeHoldervideo'
                                                        ],"alt":video.name,"height":video.fileType ? '70' :'120'}}),_c('p',{class:video.fileType ? 'mt-1 mb-0 text-center' : 'mb-0 text-center'},[_vm._v(" "+_vm._s(video.name)+" ")])])])]}}],null,true)}):_vm._e()],1)}),_c('b-card-body',{staticClass:"pt-0"},[_c('router-link',{attrs:{"to":("/lessons/" + _vm.id + "/0")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                        var href = ref.href;
                                                        var route = ref.route;
                                                        var navigate = ref.navigate;
                                                        var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"rounded overflow-hidden w-100 d-flex justify-content-center align-items-center flex-column",class:{ 'box-shadow': isActive },staticStyle:{"height":"120px","box-shadow":"0px 0px 5px 0px #0000003d"}},[_c('unicon',{staticClass:"add-icon",attrs:{"name":"plus","width":"24","fill":"#f8f8f8"}}),_c('h5',{staticClass:"text-primary pt-1"},[_vm._v(" وضع الإضافة ")])],1)])]}}])})],1)],2)],1),_c('b-col',{attrs:{"cols":"12","lg":"5","order":"2","order-md":"3","order-lg":"2"}},[_c('router-view',{attrs:{"name":"video-details","fileType":_vm.fileType}})],1),_c('b-col',{attrs:{"cols":"12","lg":"4","md":"6","order":"3","order-md":"2","order-lg":"3"}},[_c('router-view',{attrs:{"name":"video-quesstions"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }