<template>
    <div>
        <b-container fluid>
            <validation-observer ref="detailsLessonForm" tag="form">
                <b-card no-body>
                    <b-card-body
                        class="d-flex align-items-center justify-content-between flex-wrap pb-0"
                    >
                        <strong class="pb-2 m-auto mx-lg-0"
                            >تفاصيل الدرس</strong
                        >
                        <b-col cols="12" lg="8">
                            <b-row>
                                <b-col cols="12" md="4">
                                    <b-form-group
                                        label="اسم الدرس"
                                        label-for="lessonName"
                                        class="d-flex align-items-center justify-content-between"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="اسم الدرس"
                                            rules="required"
                                        >
                                            <b-form-input
                                                type="text"
                                                style="max-width: 200px"
                                                id="lessonName"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                placeholder="اسم الدرس"
                                                v-model="lessonDto.name"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="الوحدة"
                                            rules="required"
                                        >
                                            <div
                                                class="d-flex align-items-center justify-content-between"
                                            >
                                                <label
                                                    for="followUnit"
                                                    class="mr-1"
                                                    >تابع للوحدة</label
                                                >
                                                <v-select
                                                    id="followUnit"
                                                    class="flex-grow-1"
                                                    :dir="
                                                        $store.state.appConfig
                                                            .isRTL
                                                            ? 'ltr'
                                                            : 'rtl'
                                                    "
                                                    label="name"
                                                    v-model="lessonDto.unitId"
                                                    :reduce="
                                                        option => option.id
                                                    "
                                                    :options="unitsList"
                                                />
                                            </div>
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="2">
                                    <b-form-group
                                        label="الترتيب"
                                        label-for="lessonOrder"
                                        class="d-flex align-items-center justify-content-between"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="الترتيب"
                                            rules="required|positive"
                                        >
                                            <b-form-input
                                                type="number"
                                                id="lessonOrder"
                                                style="min-width: 40px; max-width: 200px"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                v-model="lessonDto.lessonOrder"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <div class="mb-1 mx-lg-0 ml-1 ml-auto">
                            <b-button
                                variant="primary"
                                size="md"
                                type="submit"
                                @click.prevent="updateLessonValidationForm"
                            >
                                تعديل
                            </b-button>
                            <b-button
                                @click.prevent="SubmitRemoveLesson()"
                                size="md"
                                class="ml-1"
                                variant="danger"
                            >
                                حذف
                            </b-button>
                        </div>
                    </b-card-body>
                </b-card>
            </validation-observer>
            <b-row>
                <b-col cols="12" lg="3" md="6" order="1">
                    <b-card no-body class="mx-auto" style="max-width: 420px">
                        <b-card-header class="pb-50">اختر نوع الملفات</b-card-header>
                        <b-form-group class="px-3">
                            <div
                                class="d-flex align-items-center justify-content-center mt-50"
                            >
                                <label>ملف فيديو</label>
                                <b-form-checkbox
                                    v-model="fileType"
                                    switch
                                ></b-form-checkbox>
                                <label>ملف pdf</label>
                            </div>
                        </b-form-group>
                        <b-card-header class="pb-50">اختر الملف</b-card-header>
                        <b-card-body
                            class="py-0"
                            v-for="(video, index) in lessonDto.lessonVideos"
                            :key="index"
                        >
                            <router-link
                                v-if="video.fileType == fileType"
                                :to="`/lessons/${id}/${video.id}`"
                                v-slot="{ href, route, navigate, isActive }"
                            >
                                <a :href="href" @click="navigate">
                                    <div
                                        class="rounded overflow-hidden mb-1"
                                        :class="{ 'box-shadow': isActive }"
                                    >
                                        <img
                                            :src="
                                                !!video.thumbPath
                                                    ? video.thumbPath
                                                    : video.fileType 
                                                            ? $store.getters[
                                                                'app/placeHolderpdf'
                                                            ]
                                                            :  $store.getters[
                                                                'app/placeHoldervideo'
                                                            ]
                                            "
                                            :alt="video.name"
                                            :height="video.fileType ? '70' :'120' "
                                            style="object-fit: contain"
                                            class="w-100"
                                        />
                                        <p :class="video.fileType ? 'mt-1 mb-0 text-center' : 'mb-0 text-center'">
                                            {{ video.name }}
                                        </p>
                                    </div>
                                </a>
                            </router-link>
                        </b-card-body>
                        <b-card-body class="pt-0">
                            <router-link
                                :to="`/lessons/${id}/0`"
                                v-slot="{ href, route, navigate, isActive }"
                            >
                                <a :href="href" @click="navigate">
                                    <div
                                        class="rounded overflow-hidden w-100 d-flex justify-content-center align-items-center flex-column"
                                        :class="{ 'box-shadow': isActive }"
                                        style="height: 120px; box-shadow: 0px 0px 5px 0px #0000003d;"
                                    >
                                        <unicon
                                            class="add-icon"
                                            name="plus"
                                            width="24"
                                            fill="#f8f8f8"
                                        ></unicon>
                                        <h5 class="text-primary pt-1">
                                            وضع الإضافة
                                        </h5>
                                    </div>
                                </a>
                            </router-link>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col cols="12" lg="5" order="2" order-md="3" order-lg="2">
                    <router-view
                        name="video-details"
                        :fileType="fileType"
                    ></router-view>
                </b-col>
                <b-col
                    cols="12"
                    lg="4"
                    md="6"
                    order="3"
                    order-md="2"
                    order-lg="3"
                >
                    <router-view name="video-quesstions"></router-view>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<style lang="scss">
label {
    padding-right: 12px;
}
.box-shadow {
    -webkit-box-shadow: 0px 0px 10px #7367f0 !important;
    box-shadow: 0px 0px 10px #7367f0 !important;
}
.add-icon {
    background: #7367f0;
    border-radius: 5px;
}
</style>
<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import {
    BButton,
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, positive } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
    props: {
        id: String,
        videoId: String
    },
    data: () => ({
        required,
        positive,
        fileType: false
    }),
    components: {
        ValidationProvider,
        ValidationObserver,
        BButton,
        BCard,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormInput,
        vSelect,
        BCol,
        BRow,
        BContainer,
        BFormCheckbox
    },
    created() {
        localize("ar");
        this.getLessonsById(this.id);
    },
    computed: {
        ...mapGetters(["videoDto","lessonDto", "unitsList"])
    },
    methods: {
        ...mapActions(["createLesson", "getLessonsById", "removeLesson", "getVideoDetils"]),
        updateLessonValidationForm() {
            this.$refs.detailsLessonForm.validate().then(success => {
                if (success) {
                    this.createLesson(this.lessonDto).then(() => {
                        // fire alert
                    });
                }
            });
        },
        SubmitRemoveLesson() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: true
            });
            swalWithBootstrapButtons
                .fire({
                    title: "هل أنت متأكد؟",
                    text: "هل فعلاً تريد حذف الدرس ؟",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "نعم!",
                    cancelButtonText: "إلغاء",
                    reverseButtons: false
                })
                .then(result => {
                    if (result.value) {
                        this.removeLesson(this.lessonDto);
                        this.$router.push({ path: "/lessons" });
                    }
                });
        }
    },
    watch: {
        fileType(val) {
            if (+this.videoId) {
                this.$router.push(`/lessons/${this.id}/0`);
            }
            if(val){
                this.videoDto.fileType = 1
            }
            else{
                this.videoDto.fileType = 0
            }
            this.getVideoDetils(0);
        }
    }
};
</script>
